@import ../../assets/styles/helpers

.label
    position: relative
    margin-bottom: 12px
    +hairline-2
    color: $n5

    &.labelHinted
        text-decoration: underline
        // text-decoration-style: dashed
        color: $p1
        cursor: pointer

.labelHint
    position: absolute
    display: inline-flex
    justify-content: center
    align-items: center
    width: 20px
    height: 20px
    top: -4px
    font-size: 12px
    margin-left: 12px
    border: 2px solid $n4
    border-radius: 50%
    color: $n5

    +t
        right: 0

.labelHintPopper
    position: absolute
    padding: 20px 16px
    border-radius: 20px
    font-weight: normal
    text-transform: none
    background: $n6
    color: $n1
    cursor: default
    transition: 0.15s ease-in-out opacity
    opacity: 0
    z-index: -99 // changed when label hovered

    +dark
        color: $n6
        background: $n2

    +t
        bottom: 28px

    &.hintVisible
        opacity: 1
        z-index: 100

.dropdown
    position: relative
    z-index: 1

    &.active
        z-index: 10

        .head
            .arrow
                svg
                    transform: rotate(180deg)

        .body
            visibility: visible
            opacity: 1
            transform: translateY(0)

.head
    position: relative
    height: 48px
    padding: 0 48px 0 16px
    border-radius: 5px
    box-shadow: inset 0 0 0 2px $n6
    background: $n8
    font-size: 14px
    font-weight: 600
    line-height: 48px
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
    user-select: none
    transition: box-shadow .2s
    font-weight: bold
    +dark
        box-shadow: inset 0 0 0 2px $n3
        background: none

    &:not(.disabled)
        cursor: pointer

        &:hover
            box-shadow: inset 0 0 0 2px #fbbf24
    
    &.noOptionSelected
        color: rgba($n6, 1)
    &.error
        box-shadow: inset 0 0 0 2px #fbbf24

.disabled .selection
    color: rgba($n3, 0.75)

    +dark
        color: $n5

.selection
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

.arrow
    position: absolute
    top: 50%
    right: 8px
    display: flex
    justify-content: center
    align-items: center
    width: 32px
    height: 32px
    border-radius: 50%
    transform: translateY(-50%)

    svg
        fill: $n2
        transition: transform .2s

    +dark
        svg
            fill: $n4

.body
    position: absolute
    top: calc(100% + 2px)
    left: 0
    right: 0
    border-radius: 5px
    background: $n8
    box-shadow: inset 0 0 0 2px $n6, 0 4px 12px rgba($n2, .1)

    visibility: hidden
    opacity: 0
    transform: translateY(3px)
    transition: all .2s
    min-width: fit-content
    +dark
        background: $n1
        box-shadow: inset 0 0 0 2px $n3, 0 4px 12px rgba($n1, .1)

.option
    margin: 0 2px
    padding: 8px 16px
    font-size: 14px
    font-weight: 600
    transition: color .2s
    cursor: pointer
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)

    &:hover,
    &.selected
        color: $p1
        background: $n7
    
    +dark
        &:hover,
        &.selected
            color: $p1
            background: $n1

.empty
    .head
        height: 26px
        padding: 0 36px 0 14px
        box-shadow: none
        background: none
        line-height: 26px

        &:hover
            box-shadow: none

    .arrow
        right: 12px
        width: 24px
        height: 24px
        box-shadow: none

    .option
        padding: 8px 14px

.optionsContainer
    width: 100%
    max-height: 265px
    overflow-y: auto
    overflow-x: visible
    min-width: fit-content
    scrollbar-width: thin
    max-height: 280px
    overflow-y: scroll
    padding: 5px
    +scrollbar-styles(#eeeeee65, transparent)

.searchBar
    position: relative
    max-width: 96%
    margin: 8px auto

    .input
        width: 100%
        height: 2rem
        padding: 0 26px 0 12px
        border-radius: 5px
        background: none
        border: 2px solid #d97706
        +poppins
        +caption-2
        color: $n2
        transition: border-color .2s
        +dark
            border-color: $n3
            color: $n8
        +placeholder
            color: $n4

        &:focus
            border-color: #d97706

        +m
            padding: 0px

    .result
        position: absolute
        top: 0
        right: 0
        bottom: 0
        width: 16px
        margin-right: 10px
        line-height: 2rem
        svg
            margin-top: -1px
            fill: $n4
            transition: fill .2s
        &:hover
            svg
                fill: $p1

.red
    color: $p3

.classDropdownIconHead
    position: relative

.classDropdownIconBody
    left: -70px
    width: 160px

.classDropdownIcon
    fill: white
    transition: transform .2s
    cursor: pointer
    &:hover
        fill:#f59e0b
