@import ../../../../assets/styles/helpers

.limits
    position: relative
    display: flex
    font-size: smaller
    align-items: center
    gap: 20px

    .left
        display: flex
        align-items: center
        font-size: 16px
        font-weight: bold

    .right
        // .info
        //     margin-bottom: 8px
        
        p
            height: 18px

.link
    color: $n8
    text-decoration: underline
    font-weight: bold
