@import ../../assets/styles/helpers

.alert
    position: relative
    display: flex
    justify-content: center
    align-items: center
    color: $n8

    background: $p3

    &.blue
        background: $p1

    &.purple
        background: $p2

    &.green
        background: $p4

    .contentContainer
        margin: 12px 20px
        margin-right: 44px

    .textContainer
        text-align: center
        max-width: 600px

    .close
        position: absolute
        display: flex
        justify-content: center
        align-items: center
        width: 12px
        height: 12px
        z-index: 3
        top: 16px
        right: 16px
        fill: $n8
        stroke: $n8
        stroke-width: 2px
        cursor: pointer

        +m
            top: 50%
            transform: translateY(calc(-50%))
