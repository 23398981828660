[class^="button"]
    display: inline-flex
    justify-content: center
    align-items: center
    height: 48px
    cursor: pointer
    padding: 0 44px
    background: $n1
    color: $n8
    +dark-common
        background: $n8
        color: $n1

    font-weight: 900 !important
    border-radius: 10px
    +button-1
    text-align: center
    
    transition: all .2s

    &:hover
        background: darken($p1, 15)

    &:disabled,
    &.disabled
        opacity: .5
        pointer-events: none

    svg
        fill: $n8
        transition: all .2s

    &:not([class^="button-circle"])
        svg
            &:first-child
                margin-right: 12px

            &:last-child
                margin-left: 12px

.button-stroke
    background: none !important
    box-shadow: 0px
    color: $n2
    border:2px solid #00000070

    svg
        fill: $n4

    +dark-common
        box-shadow: 0px
        border:2px solid #ffffff70
        color: $n8
        svg
            fill: $n8

    &:disabled,
    &.disabled
        opacity: .5
        pointer-events: none

    &:hover,
    &.active
        background: $n2 !important
        box-shadow: 0 0 0 2px $n2 inset
        color: $n8

        svg
            fill: $n8

        +dark-common
            background: $n3 !important
            box-shadow: 0 0 0 2px $n3 inset


.button-blue-stroke
    background: none !important
    box-shadow: 0px
    color: $n2

    svg
        fill: $n4

    +dark-common
        box-shadow: 0px
        border:1px solid $p5
        color: $n8
        svg
            fill: $n8

    &:disabled,
    &.disabled
        opacity: .5
        pointer-events: none

    &:hover,
    &.active
        background: $n2 !important
        box-shadow: 0 0 0 2px $n2 inset
        color: $n8

        svg
            fill: $n8

        +dark-common
            background: $n3 !important
            box-shadow: 0 0 0 2px $n3 inset


.button-black
    background: $n1 !important

    &:hover,
    &.active
        background: $n2 !important

    +dark-common
        background: $n7 !important
        color: $n2
        svg
            fill: $n2
        &:hover,
        &.active
            background: $n6 !important

.button-blue, .button.button-blue
    background: $p5 !important
    color: $n8
    +dark
        color: $n8 !important
    &:hover,
    &.active
        background: darken($p3, 8)

.button-red
    background: $p3 !important
    color: $n8

    +dark-common
        color: $n8

    &:hover,
    &.active
        background: darken($p3, 8)

.button-green
    background: $p4 !important
    color: $n8

    +dark-common
        color: $n8

    &:hover,
    &.active
        background: darken($p4, 8)

.button-white
    background: $n8 !important
    color: $n2

    svg
        fill: $n2

    &:hover
        background: $n7

.button-small
    height: 40px
    border-radius: 10px
    padding: 0 26px
    font-size: 14px

.button-mini
    height: 28px
    border-radius: 6px
    font-weight: normal
    padding: 2px 17px
    font-size: 12px

.button-border-circle
    display: inline-block
    height: min-content
    max-width: max-content
    user-select: none
    padding: 8px 16px
    border-radius: 16px
    font-size: 14px

.button-border-circle-stroke
    display: inline-block
    height: min-content
    max-width: max-content
    user-select: none
    padding: 8px 16px
    border-radius: 16px
    font-size: 14px
    background: none !important
    box-shadow: inset 0 0 0 2px #E6E8EC
    color: $n2
    opacity: 1

    &:hover
        opacity: 0.8
        background: none

    +dark-common
        color: $n8
        box-shadow: inset 0 0 0 2px #353945

.button-circle
    flex: 0 0 48px
    width: 48px
    height: 48px
    padding: 0
    border-radius: 50%

.button-circle-stroke
    flex: 0 0 48px
    width: 48px
    height: 48px
    padding: 0
    border-radius: 50%
    box-shadow: 0 0 0 2px $n6 inset
    background: transparent !important
    transition: all .2s

    svg
        fill: $n4

    &:hover
        background: $n2
        box-shadow: 0 0 0 2px $n2 inset

        svg
            fill: $n8

    +dark-common
        box-shadow: 0 0 0 2px $n3 inset
        &:hover
            background: $n3

.button-circle-stroke.button-small
    flex: 0 0 40px
    width: 40px
    height: 40px

[class^="group-buttons"]
    padding: 10px 0
    display: flex
    justify-content: space-between

.group-buttons-right
    justify-content: right

    .button
        margin-left: 15px

.button svg.icon_custom_component
    fill: $n1
    +dark-common
        fill: $n8