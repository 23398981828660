@import ../../assets/styles/helpers

.title
    margin-bottom: 32px
    padding-right: 56px
    +m
        +poppins
        font-size: 24px
        line-height: 32px
        font-weight: 600

    svg
        position: relative
        top: -2px
        margin-right: 16px
        +m
            top: 0
        +dark
            fill: $n8

.field,
.dropdownContainer,
.wrap,
.box,
.checkbox
    &:not(:last-child)
        margin-bottom: 32px

.dropdownHead
    margin-bottom: 4px

    &.red
        box-shadow: inset 0 0 0 2px #FE8375

.note
    margin-top: 8px
    font-size: 10px
    line-height: 1.2
    font-weight: 600
    color: #9396B9

.checkbox
    max-width: max-content

.bgError 
    background-color: $p3 !important   

.wrap
    display: flex
    flex-direction: column
    padding: 20px 24px
    border-radius: 4px
    background: $n7
    +dark
        background: $n3
    
    .balance
        display: flex
        align-items: center
        justify-content: space-between

        &:not(:last-child)
            margin-bottom: 16px
            padding-bottom: 16px
            border-bottom: $n6 1px solid

            +dark
                border-bottom: rgba($n2, 0.4) 1px solid

.category
    margin-right: auto
    font-weight: 600
    color: $n3
    +dark
        color: $n7

.details
    text-align: right

.currency
    +body-bold-2

.price
    color: $n4

.limits
    display: flex

    div
        &:first-child
            flex: 1
            display: flex
            align-items: center
            +body-bold-1
            color: $p3

            +m
                font-size: 18px

        &:last-child
            flex: 2
            color: $p3

// .blue
//     color: $p1

.price_total
    color: $n4
    transform: translateY(-24px)

.box
    position: relative

    .button
        position: absolute
        top: 36px
        right: 16px
        height: 24px
        padding: 0 16px
        border-radius: 12px


.withdraw
    text-align: left

.withdraw > .button
    width: 100%

.link
    color: $n8
    text-decoration: underline
    font-weight: bold

.linkLimit
    color: white
    border: 2px solid white
    padding: .2em 1em
    margin-top: .5em
    text-decoration: none
    font-weight: bold
    display: block
    width: fit-content
    float: right

.btns
    display: flex
    justify-content: center
    margin: 10px 0
    gap: 20px

    +m
        margin-bottom: 24px

    .active
        background: darken($p1, 20)

.error
    color: $p3

.buttonSubmit
    margin-top: 24px
    width: 100%

.hidden
    display: none

.page
    padding: 100px
    +m
        padding: 64px 0

    .wrapper
        padding: 0 16%
        +x
            padding: 0 14%
        +d
            padding: 0 12%
        +t
            padding: 0 10%
        +m
            padding: 0 8%
        +a
            padding: 0 6%
        +sa
            padding: 0 4%
        +s
            padding: 0
        margin: 0 auto
        text-align: center

.button-container
    width: 100%
    display: flex

.button-container > .button
    width: 140px
    margin: auto

.error-alert
    position: relative
    display: flex
    justify-content: center
    align-items: center
    color: $n8
    font-size: medium
    border-radius: 10px
    background: $p3
    padding: 15px
    margin-bottom: 30px

.linkToast
    color: $n8
    text-decoration: underline
    font-weight: bold
    cursor: pointer