// common styles
html
    scrollbar-width: thin
    scroll-behavior: smooth

body
    //min-width: 300px
    background: $n8
    +roobert
    font-size: 14px
    line-height: 1.7
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    color: $n2
    +dark-body
        background: $n1
        color: $n8

a
    text-decoration: none

svg,
img
    vertical-align: middle

.desktop
    &-hide
        +d
            display: none !important

    &-show
        display: none !important
        +d
            display: block !important

    &-text-right
        +d
            text-align: right

.tablet
    &-hide
        +t
            display: none !important

    &-show
        display: none !important
        +t
            display: block !important

.mobile
    &-hide
        +m
            display: none !important

    &-show
        display: none !important
        +m
            display: block !important