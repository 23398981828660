@import ../../../../assets/styles/helpers

.wrap
    position: relative

.card_bg_wrap
    position: absolute
    margin-left: 10px
    z-index: 0
    width: 330px !important
    height: 146px !important
    border-radius: 20px
    overflow: hidden

.card_bg
    width: 670px
    height: 424px
        
.flex
    display: flex
    flex-wrap: wrap
    align-content: center
    justify-content: space-around
    width: 100%
    padding: 1em 0
    //width: 100vw
    overflow: scroll

.row
    height: 146px !important
    display: flex !important
    justify-content: space-between
    color: $n6
    transition: background .2s
    .col
        padding-top: 20px
        align-items: stretch
        display: grid
        +caption-bold-2
        color: $n6
        +dark
            border-color: $n2

    &:not(:first-child):not(:last-child)
        .col
            +dark
                border-color: $n2

    // &:not(:first-child)
    //     &:hover
    //         background: $n7

.col
    display: table-cell
    padding: 10px 4px
    +m
        padding: 10px 8px
    &:first-child
        padding-left: 32px
    &:last-child
        padding-right: 32px
    &:nth-child(3),
    &:nth-child(4),
    &:last-child
        text-align: right

.currency
    position: absolute
    right: 32px
    bottom: 10px
    display: flex
    align-items: flex-end
    justify-content: flex-end
    padding: 5px 0
    gap: 10px
    
.currency .item
    opacity: 0.6
    -webkit-transition-duration: .28s
    transition-duration: .28s
    -webkit-transition-property: opacity, -webkit-box-shadow, -webkit-transform
    transition-property: opacity, -webkit-box-shadow, -webkit-transform
    transition-property: box-shadow, transform, opacity
    transition-property: box-shadow, transform, opacity, -webkit-box-shadow, -webkit-transform
    -webkit-transition-timing-function: cubic-bezier(.4, 0, .2, 1)
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
    -webkit-user-select: none
    background: #f5f5f5
    border-radius: 10px
    padding: 5px
    height: 30px

    &:hover
        opacity: 1

.link
    position: relative
    bottom: -17px
    a
        transition: all .28s
        color: $n6
        font-size: 1.1rem
        &:visited
            color: $n6
        &:hover
            color: $n8

.icon
    display: flex
    height: 40px
    gap: 12px
    margin-right: 20px
    +m
        margin-right: 12px
    img
        width: 40px
        height: 40px    

.info
    font-weight: 900
    font-size: 25px

.usdt
    font-size: 23px
    margin-bottom: 2px

.text
    color: $n6
    font-size: 13px
    opacity: 0.8

.card
    position: relative
    z-index: 1
    border-radius: 20px
    margin: 0 10px
    width: 330px !important
    +m
        margin: 5px

.not_found
    display: flex
    justify-content: center
    align-items: center
    height: 220px

.link2
    text-decoration: underline
    font-weight: bold
    color: $n8