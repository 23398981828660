[class^="section"]
    margin-bottom: 24px

.section-bg
    padding: 136px 0
    background: $n7
    +d
        padding: 112px 0
    +m
        padding: 64px 0
    +dark-common
        background: #18191D

.section-mb0
    margin-bottom: 0
    +d
        margin-bottom: 0
    +m
        margin-bottom: 0

.section-padding
    padding: 136px 0
    +d
        padding: 112px 0
    +m
        padding: 64px 0
