:root {
    --primary: #63e;
    --primary-light: #8e64ff;
    --grey-00: #fff;
    --grey-10: #f4f4ff;
    --grey-20: #ececfb;
    --grey-30: #dfe1f4;
    --grey-40: #babcd2;
    --grey-50: #9fa2b9;
    --grey-60: #5e6077;
    --grey-70: #383a4d;
    --grey-80: #2a2b3a;
    --grey-90: #171825;
    --grey-100: #010314;
    --error: #e23453;
    --bg-body: var(--grey-100);
    --border-color: var(--grey-80);
    --material-effect: 0 0 0 2px rgba(0, 0, 0, .05), 0 0 0 1px hsla(0, 0%, 100%, .1), inset 0 0.5px 0 hsla(0, 0%, 100%, .5);
    --text-muted: var(--grey-40);
    --text-body: var(--grey-30);
    --text-headings: #fff;
    --text-links: var(--primary-60);
    --text-links-hover: var(--primary-40);
    --text-code: var(--text-headings);
    --text-ul-bullet: var(--green-medium);
    --text-ol-bullet: var(--green-medium);
    --text-ol-bullet-color: var(--green-darkest);
    --text-highlight: rgba(136, 89, 255, .2);
    --text-highlight-hover: rgba(136, 89, 255, .35);
    --btn-bg-glow: hsla(0, 0%, 100%, .4);
    --btn-bg-primary: rgba(133, 92, 241, .8);
    --btn-bg-primary-hover: var(--btn-bg-primary);
    --btn-color-primary: #fff;
    --btn-bg-secondary: rgba(52, 44, 65, .8);
    --btn-bg-secondary-hover: var(--btn-bg-secondary);
    --btn-color-secondary: var(--text-headings);
    --btn-shadow: inset 0 1px 0 hsla(0, 0%, 100%, .1), 0 1px 0 rgba(0, 0, 0, .05);
    --index-ventures: #fff;
    --sequoia: #fff;
    --kleiner-perkins: #fff;
    --fw-hair: 100;
    --fw-thin: 200;
    --fw-extralight: 250;
    --fw-light: 300;
    --fw-book: 350;
    --fw-regular: 400;
    --fw-medium: 500;
    --fw-semibold: 600;
    --fw-bold: 700;
    --fw-black: 800;
    --fw-heavy: 900;
    --fw-h1: var(--fw-medium);
    --fw-h2: var(--fw-medium);
    --fw-h3: var(--fw-medium);
    --fw-h4: var(--fw-medium);
    --fw-h5: var(--fw-medium);
    --fw-h6: var(--fw-medium);
    --font-size-root: 16px;
    --font-size-base: 16px;
    --font-size-sm: 0.7rem;
    --font-size-xs: 0.6rem;
    --font-size-xxs: 0.5rem;
    --font-size-h1: 4.25rem;
    --font-size-h2: 3.5rem;
    --font-size-h3: 1.6rem;
    --font-size-h4: 1.2rem;
    --font-size-h5: 1.05rem;
    --font-size-h6: 0.9rem;
    --line-height-base: 1.55;
    --line-height-sm: 1.428571429;
    --line-height-xs: 1.142857143;
    --line-height-h1: 1;
    --line-height-h2: 1.05;
    --line-height-h3: 1.25;
    --line-height-h4: 1.3;
    --line-height-h5: 1.142857143;
    --line-height-h6: 1.555555556;
    --margin-bottom-base: var(--token-20);
    --headings-margin-bottom: var(--margin-bottom-base);
    --link-decoration: none;
    --link-hover-decoration: none;
    --width: 90vw;
    --max-width: 1200px;
    --token-02: 0.1rem;
    --token-04: 0.2rem;
    --token-08: 0.4rem;
    --token-12: 0.6rem;
    --token-16: 0.8rem;
    --token-20: 1rem;
    --token-24: 1.2rem;
    --token-32: 1.6rem;
    --token-40: 2rem;
    --token-48: 2.4rem;
    --token-56: 2.8rem;
    --token-64: 3.2rem;
    --token-72: 3.6rem;
    --token-80: 4rem;
    --token-88: 4.4rem;
    --token-96: 4.8rem;
    --token-104: 5.2rem;
    --token-112: 5.6rem;
    --token-120: 6rem;
    --token-128: 6.4rem;
    --token-156: 7.8rem;
    --radius-round: 50%;
    --radius-lg: var(--token-32);
    --radius-md: var(--token-24);
    --radius-sm: var(--token-12);
    --radius-xs: var(--token-08);
    --radius-xxs: var(--token-04);
    --shadow-sm: rgba(50, 50, 93, .08) 0 8px 18px -4px;
    --shadow-md: rgba(50, 50, 93, .25) 0 12px 28px -4px;
    --iso-rotation: rotateX(60deg) rotateY(0deg) rotateZ(45deg);
    --perspective: 1600px;
    --ease-in-out: cubic-bezier(0.45, 0, 0.55, 1);
    --font-family-base: Roobert, sans-serif;
}
.head-spl-lin {
    background: hsla(0,0%,100%,.15);
    height: 16px;
    margin: 16px 10px 16px 20px;
    width: 1px;
}


.Navigation_navbar__drXfa {
    display: flex;
    align-items: center;
    gap: var(--token-20);
    height: var(--token-56);
    justify-content: center;
    padding: 0 var(--token-16);
}
.Header_wrap__H1HZw {
    margin-right: auto;
}

.Header_nav__andd3 {
    display: flex;
}


@media (max-width: 799px) {
    .Header_header__pXml_ {
        padding: 0 var(--token-12) 0 var(--token-20);
        position: fixed;
        width: calc(100% - var(--token-40));
        height: var(--token-64);
        background: var(--grey-100);
        z-index: 100;
        border-radius: var(--token-40);
        border: 1px solid var(--grey-70);
        left: var(--token-20);
        top: var(--token-20);
    }

    /* .Header_theme__E98mT {
        display: none !important;
    } */

    .Navigation_island__hx7CL {
        display: none !important;
    }

    .height-elevator {
        height: 80px;
    }
}