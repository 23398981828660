@import 'helpers'

.loader_light
    height: 101vh
    width: 101vw
    display: flex
    justify-content: center
    align-items: center

.loader_dark
    height: 101vh
    width: 101vw
    display: flex
    justify-content: center
    align-items: center
    background: $n1