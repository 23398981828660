
/* .pt-5 {
    padding-top: 1.25rem;
} */


img, video {
    max-width: 100%;
    height: auto;
}

.footer {
    z-index: 2;
    width: 100%;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    margin-top: auto;
    font-family: Roobert, sans-serif;
}

.footer-spl-lin {
    background: hsla(0,0%,100%,.25);
    height: 18px;
   
    width: 1px;
}

.footercontainer {
    max-width: 1064px;
    padding: 16px;
}

.w-container {
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;
}

.w-container:after, .w-container:before {
    content: " ";
    grid-area: 1/1/2/2;
    display: table;
}

.legallink {
    opacity: 0.5;
    color: #fff;
    margin-left: 0;
    font-size: 12px;
    font-weight: 400;
    text-decoration: underline;
    transition: opacity 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.light-mode .main .legallink{
    color: #000;
}
.footer-links-title {
    letter-spacing: normal;
    text-transform: none;
    white-space: nowrap;
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
}

.light-mode .bg-black .footer-links-title{
    color: #fff;
}

.footer-link-2 {
    letter-spacing: normal;
    text-transform: none;
    padding-top: 8px;
    padding-bottom: 8px;
    text-decoration: none;
    transition: color 0.2s;
    font-family: Roobert, sans-serif;
    color: #909090;
    cursor: poRoobert;
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
}
.image-38 {
    width: 24px;
    opacity: 1;
}

.socialicons {
    grid-column-gap: 16px;
    align-items: center;
    display: flex;
}
.socialicon {
    width: 20px;
    opacity: 0.5;
    align-items: center;
    transition: opacity 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    display: flex;
}
.w-inline-block {
    max-width: 100%;
    display: inline-block;
}



.w-layout-grid {
    grid-row-gap: 16px;
    grid-column-gap: 16px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
}

.footer-links-grid {
    grid-column-gap: 70px;
    grid-row-gap: 70px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    line-height: 1;
}

.footerlegal {
    grid-column-gap: 0;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
    display: flex;
}

.dividerline {
    height: 1px;
    opacity: 0.15;
    background-color: #fff;
    border-top: 1px rgba(255, 255, 255, 0.1);
    border-bottom: 1px rgba(255, 255, 255, 0.1);
    justify-content: space-between;
    margin-top: 8px;
    margin-bottom: 16px;
    padding-bottom: 0;
    display: block;
}

.light-mode .main .dividerline{
    background-color: #000;
}

.text-right {
    text-align: right;
}
/* .gap-5 {
    gap: 1.25rem;
} */

/* .flex {
    display: flex;
    align-items: center;
} */
/* .w-60{
    width: 60% !important;
} */

.lowerfooter {
    display: flex;
    flex-wrap: nowrap;
    gap: 1.25rem;
}
.footertext {
    color: rgba(255, 255, 255, 0.5);
    font-family: Roobert, sans-serif;
    font-size: 12px;
}
.legalimages {
    flex-shrink: 0;
    /* width: max-content; */
}
.light-mode .main .footertext {
    color: rgba(0, 0, 0, 0.5);
}

.legallinks {
    grid-column-gap: 24px;
    align-items: center;
    margin-top: 24px;
    display: flex;
}

.justify-end {
    justify-content: flex-end;
}

.mr-16 {
    margin-right: 4rem;
}

.w-container:after {
    clear: both;
}

.w-container:after, .w-container:before {
    content: " ";
    grid-area: 1/1/2/2;
    display: table;
}

@media screen and (max-width: 991px){
    .footercontainer {
        padding-left: 24px;
        padding-right: 24px;
    }
    .footer-links-grid {
        grid-column-gap: 60px;
        grid-template-rows: auto auto;
        grid-template-columns: 1.5fr 1fr 1fr 1fr;
        margin-bottom: 60px;
    }
    .footer-link-2 {
        line-height: 22px;
    }
    }

    

@media (min-width: 767px){
    .isMobile{
        display: none !important;
    }
}

@media screen and (max-width: 767px){
.footer-links-grid {
    grid-template-columns: 1fr 1fr;
    margin-bottom: 60px;
}
.isDesktop{
    display: none;
}
.legalimages {
    flex-direction: column;
    width: 55px;
}
}

@media screen and (max-width: 479px){
    .footercontainer {
        padding: 40px 28px;
    }
.footer-links-grid {
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    text-align: center;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
    margin-bottom: 0;
}
.footer-link-column {
    text-align: left;
    margin-bottom: 0;
}
.footer-link-2 {
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 12px;
    line-height: 20px;
}
}
