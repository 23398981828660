@import './helpers'

.toast_main
    max-width: 1000px

    +m
        // margin-top: 48px
        max-width: 350px

    +dark
        background: $n2
        color: $n8

.toast_success
    max-width: 1000px
    background: $p4
    color: $n8

    +m
        // margin-top: 48px
        max-width: 350px

.toast_error
    max-width: 1000px
    background: $p3
    color: $n8

    +m
        // margin-top: 48px
        max-width: 350px

.toastError
    max-width: 1000px

    +m
        // margin-top: 48px
        max-width: 350px
