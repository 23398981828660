@import ../../../assets/styles/helpers

.wrap
    display: flex
    padding: 20px 24px
    border-radius: 4px
    background: $n7
    margin-bottom: 20px
    +dark
        background: $n3

.category
    margin-right: auto
    font-weight: 600
    color: $n3
    +dark
        color: $n6

.details
    text-align: right

.currency
    +body-bold-2

.price
    color: $n4

.note
    margin-top: 12px
    +caption-3
    font-weight: 600
    color: $n4
