@import ../helpers

.table-responsive
    +t
        overflow-x: scroll

.table-grid
    display: table
    width: 100%

    .row
        display: table-row
        color: $n2
        cursor: pointer
        +t
            min-width: fit-content
            position: relative

        &:first-child
            .col
                vertical-align: top
                padding: 0 16px 32px
                border-bottom: 1px solid $n6
                +caption-bold-2
                color: $n4
                +t
                    padding: 5px
                +dark
                    border-color: $n2
                svg
                    margin-left: 4px
                    fill: $n4

        &:not(:first-child)
            transition: background .2s

            .col
                &:first-child
                    border-radius: 12px 0 0 12px
                    color: $n4
                    +t
                        border-radius: 0

                &:last-child
                    border-radius: 0 12px 12px 0
                    +t
                        border-radius: 0

            &:hover
                background: $n7
                +dark
                    background: $n2

                .button
                    display: inline-flex

        &:not(:last-child)
            +t
                margin-bottom: 24px
                border-bottom: 1px solid $n6
                +dark
                    border-color: $n3

    .col
        display: table-cell
        padding: 16px
        text-align: right
        font-weight: 600
        +t
            margin-bottom: 4px
        +dark
            color: $n8

        &:first-child,
        &:nth-child(2)
            text-align: left

        &:nth-child(2)
            margin: 16px 0

        &:first-child
            font-size: 12px
            +t
                display: none

        &:last-child
            +t
                margin-bottom: 0

