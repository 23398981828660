@import ../../../assets/styles/helpers

.label
    margin-bottom: 12px
    +hairline-2
    color: $n5

.dropdown
    position: relative
    z-index: 3

    &.active
        z-index: 10

        .head
            .arrow
                svg
                    transform: rotate(180deg)

        .body
            visibility: visible
            opacity: 1
            transform: translateY(0)

.head
    position: relative
    height: 48px
    padding: 0 48px 0 16px
    border-radius: 5px
    box-shadow: inset 0 0 0 2px $n6
    background: $n8
    font-size: 14px
    font-weight: 600
    line-height: 48px
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
    user-select: none
    transition: box-shadow .2s
    +dark
        box-shadow: inset 0 0 0 2px $n3
        background: none

    &:not(.disabled)
        cursor: pointer

        &:hover
            box-shadow: inset 0 0 0 2px #d97706

.disabled .selection
    color: $n4

    +dark
        color: $n4

.selection
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

.arrow
    position: absolute
    top: 50%
    right: 8px
    display: flex
    justify-content: center
    align-items: center
    width: 32px
    height: 32px
    border-radius: 50%
    transform: translateY(-50%)

    svg
        fill: $n2
        transition: transform .2s

    +dark
        svg
            fill: $n4

.contentInTop
    padding: 8px 18px
    margin-bottom: 8px

    .title
        font-size: 18px
        font-weight: bold

.body
    position: absolute
    top: calc(100% + 2px)
    left: 0
    right: 0
    padding: 8px 0
    border-radius: 5px
    background: $n8
    box-shadow: inset 0 0 0 2px $n6, 0 4px 12px rgba($n2, .1)

    visibility: hidden
    opacity: 0
    transform: translateY(3px)
    transition: all .2s
    min-width: fit-content
    +dark
        background: $n1
        box-shadow: inset 0 0 0 2px $n3, 0 4px 12px rgba($n1, .1)

.option
    margin: 4px 2px
    padding: 8px 16px
    font-size: 14px
    font-weight: 600
    transition: color .2s
    cursor: pointer
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)

    display: flex
    flex-direction: column
    gap: 8px

    +m
        gap: 16px

    .col
        display: flex
        gap: 12px
        align-items: center
        
        .name
            +body-1

        .category
            height: min-content

        .label
            margin-bottom: 0
            +hairline-2
            color: $n5
        
        +m
            &:not(:first-child)
                align-items: flex-start

    .addressCol
        div
            display: flex
            justify-content: flex-start
            align-items: center
            gap: 8px

        +m
            flex-direction: column

            div
                justify-content: space-between
                align-items: center

        .fullAddress
            display: block

        .shortAddress
            display: none

        +m
            .fullAddress
                display: none

            .shortAddress
                display: block

    &:hover,
    &.selected
        background: $n7
    
    +dark
        &:hover,
        &.selected
            background: rgba($n2, 0.4)

.addNewAddress
    width: 100%
    padding: 16px 0
    display: flex
    justify-content: center
    border-top: 2px solid $n6

    +dark
        border-top: 2px solid $n3

    a
        font-size: 16px
        color: $n1

        +dark
            color: $n8

        &:hover
            text-decoration: underline
            color: rgba($n1, 0.7)
            +dark
                color: rgba($n8, 0.7)

.empty
    .head
        height: 26px
        padding: 0 36px 0 14px
        box-shadow: none
        background: none
        line-height: 26px

        &:hover
            box-shadow: none

    .arrow
        right: 12px
        width: 24px
        height: 24px
        box-shadow: none

    .option
        padding: 8px 14px

.title
    margin-bottom: 16px

    span
        color: $p3

.noData,
.demoData
    padding-top: 24px
    display: flex
    flex-grow: 1
    justify-content: center
    align-items: center

.gray
    color: gray

.note
    +caption-2
    font-weight: 600
    color: $n4
