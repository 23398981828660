// media queries
=w
    @media only screen and (max-width: "1419px")
        @content

=x
    @media only screen and (max-width: "1339px")
        @content

=d
    @media only screen and (max-width: "1179px")
        @content

=t
    @media only screen and (max-width: "1023px")
        @content

=m
    @media only screen and (max-width: "767px")
        @content

=a
    @media only screen and (max-width: "639px")
        @content

=sa
    @media only screen and (max-width: "576px")
        @content

=s
    @media only screen and (max-width: "474px")
        @content

// fonts
=roobert
    font-family: 'Roobert', sans-serif

=britanica
    font-family: 'Britanica', sans-serif    

=montserrat
    font-family: 'Montserrat', sans-serif    

=poppins
    font-family: 'Poppins', sans-serif

// colors
$p1: #40bdeb
$p2: #9757D7
$p3: #FE8375
$p4: #13B195
$p5: #37ABBA

$s1: #c0e0ff
$s2: #E4D7CF
$s3: #FFD166
$s4: #CDB4DB
$white: #ffffff

$n1: #141414
$n2: #181818
$n3: #292D33
$n4: #9396B9
$n5: #B1B5C3
$n6: #E6E8EC
$n7: #F4F5F6
$n8: #FCFCFD
$n9: #FFFFFFd0

// typography
=body-1
    font-size: 24px
    line-height: 1.3
    letter-spacing: -.01em

=body-bold-1
    font-size: 24px
    line-height: 1.3
    font-weight: 800
    letter-spacing: -.01em

=body-2
    font-size: 16px
    line-height: 1.5

=body-bold-2
    font-size: 16px
    line-height: 1.5
    font-weight: 600

=caption-1
    font-size: 14px
    line-height: 1.7

=caption-bold-1
    font-size: 14px
    line-height: 1.7
    font-weight: 600

=caption-2
    font-size: 12px
    line-height: 1.5

=caption-bold-2
    font-size: 12px
    line-height: 1.5
    font-weight: 600

=caption-3
    font-size: 10px
    line-height: 1.2

=hairline-1
    font-size: 16px
    line-height: 1
    font-weight: 700
    text-transform: uppercase

=hairline-2
    font-size: 12px
    line-height: 1
    font-weight: 700
    text-transform: uppercase

=button-1
    +britanica
    font-size: 16px
    font-weight: 700
    line-height: 1

=button-2
    +britanica
    font-size: 14px
    line-height: 1.15
    font-weight: 700

//triangle
=arr($width, $height, $bg, $direction)
    width: 0
    height: 0
    border-style: solid
    @if $direction == t
        border-width: 0 $width / 2 + px $height + px $width / 2 + px
        border-color: transparent transparent $bg transparent
    @if $direction == r
        border-width: $height / 2 + px 0 $height / 2 + px $width + px
        border-color: transparent transparent transparent $bg
    @if $direction == b
        border-width: $height + px $width / 2 + px 0 $width / 2 + px
        border-color: $bg transparent transparent transparent
    @if $direction == l
        border-width: $height / 2 + px $width + px $height / 2 + px 0
        border-color: transparent $bg transparent transparent
    @if $direction == tl
        border-width: $height + px $width + px 0 0
        border-color: $bg transparent transparent transparent
    @if $direction == tr
        border-width: 0 $width + px $height + px 0
        border-color: transparent $bg transparent transparent
    @if $direction == br
        border-width: 0 0 $height + px $width + px
        border-color: transparent transparent $bg transparent
    @if $direction == bl
        border-width: $width + px 0 0 $height + px
        border-color: transparent transparent transparent $bg
// example
// +arr(500,50, #000, l)
// +arr(500,50, #000, bl)

// placeholder
=placeholder
    &::placeholder
        @content

// media query width
=r($width)
    @media only screen and (max-width: $width+ "px")
        @content

=rmin($width)
    @media only screen and (min-width: $width+ "px")
        @content

=text-overflow
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

// theme
=dark
    @at-root :global(.dark-mode) &
        @content

=dark-body
    @at-root .dark-mode
        @content

=dark-common
    @at-root .dark-mode &
        @content

=scrollbar-styles($thumb-background, $track-background)
    &::-webkit-scrollbar
        height: 3px
        width: 3px

        &-button
            background: transparent

        &-track-piece
            background: transparent

        &-thumb
            background: $thumb-background

    &::-webkit-scrollbar-thumb
        background: $thumb-background

    &::-webkit-scrollbar-track
        background: $track-background

    &::-webkit-scrollbar-corner
        background: $track-background

    -ms-overflow-style: none

    &::-ms-scrollbar-thumb
        background: $thumb-background

    &::-ms-scrollbar-track
        background: $track-background