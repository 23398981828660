@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap')
  
  
// tailwind
@tailwind base
@tailwind components
@tailwind utilities

// common styles 
@import reset
@import helpers
@import common

// global classes
@import blocks/section
@import blocks/container
@import blocks/title
@import blocks/category
@import blocks/button
@import blocks/stage
@import blocks/favorite
@import blocks/sorting
@import blocks/slick
@import blocks/datepicker
@import blocks/table

//@import ./blocks/captcha


html, body
  font-family: "IBM Plex Sans", sans-serif
  font-weight: 400
  font-style: normal
  scrollbar-gutter: stable
  max-width: 100vw

* 
  scrollbar-width: thin
  box-sizing: border-box

