@import ../../../assets/styles/helpers

.label
    margin-bottom: 12px
    +hairline-2
    color: $n5

.wrap
    position: relative

.input
    width: 100%
    height: 48px
    padding: 0 14px
    border-radius: 5px
    border: 1px solid $n7
    background: $n7
    color: $n2
    transition: border-color .2s
    &:-webkit-autofill,
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus, 
    &:-webkit-autofill:active
        -webkit-background-clip: text
        -webkit-text-fill-color: $n2
        transition: background-color 5000s ease-in-out 0s
        box-shadow: inset 2px 4px 30px 30px $n7
        +dark
            -webkit-text-fill-color: $n9
            box-shadow: inset 2px 4px 30px 30px $n3

    +dark
        border-color: $n3
        background: $n3
        color: $n9
        +placeholder
            color: $n9

    &:focus
        border-color: #fbbf24

.toggle,
.preview
    position: absolute
    top: 0
    right: 0
    bottom: 0
    width: 48px

.preview,
.toggle
    svg
        fill: $n5
        transition: fill .2s

    &:hover
        svg
            fill: $n4

    &.active
        svg
            fill: $n1

    +dark
        svg
            fill: $n9
        &:hover
            svg
                fill: $n5
        &.active
            svg
                fill: $n8

.empty
    .input
        height: 26px
        border: none

.view,
.icon
    .input
        padding-right: 48px

.note
    margin-top: 12px
    +caption-3
    font-weight: 600
    color: $n4

.error_wrapper
    border: 1px solid $p3

.error
    color: $p3

    .input
        border-color: $p3

.disabled
    color: rgba($n3, 0.75)

    +dark
        color: $n5