@import ../helpers

[class^="category"]
    display: inline-block
    padding: 8px 8px 6px
    border-radius: 4px
    +hairline-2
    background: $n6
    color: $n1

    +dark-common
        background: $n2
        color: $n8

.category-blue
    background: $p1
    color: $n8


.category-purple
    background: $p2
    color: $n8


.category-green
    background: $p4
    color: $n8


.category-red
    background: $p3
    color: $n8


.category-gray
    background: $n5
    color: $n8

    +dark-common
        background: $n3
        color: $n8

.category-stroke-green
    background: none
    box-shadow: inset 0 0 0 2px $p4
    color: $p4
