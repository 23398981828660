@import ../../assets/styles/helpers

.title
    margin-top: 20px
    margin-bottom: 32px
    padding-right: 56px
    +m
        margin-bottom: 16px
        +poppins
        font-size: 24px
        line-height: 32px
        font-weight: 600

    svg
        position: relative
        top: -2px
        margin-right: 16px
        +m
            top: 0
        +dark
            fill: $n8

.field,
.wrap,
.box
    &:not(:last-child)
        margin-bottom: 20px
        +m
            margin-bottom: 16px

.field_wrap
    display: flex
    align-items: center
    gap: 10px

    .field
        width: 100%
        margin-bottom: 20px

    .sign
        width: 34px

.fieldError
    border: solid 1px $p3

.sign
    display: flex
    justify-content: center
    align-items: center
    width: 32px
    height: 32px
    margin-top: -20px
    border-radius: 50%
    background: $n6
    cursor: pointer

    svg
        fill: $n3

    +dark
        background: $n3
        svg
            fill: $n6

.box
    position: relative

    margin-bottom: 0

    .button
        position: absolute
        top: 36px
        right: 16px
        height: 24px
        padding: 0 16px
        border-radius: 12px

.transfer > .button
    width: 100%

.max_amount_field
    margin-bottom: 0

.note
    margin-top: 12px
    +caption-3
    font-weight: 600
    color: $n4
